import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Dexie, { type EntityTable } from 'dexie';
import { Lead, User } from 'app-graphql';
import { useLiveQuery } from 'dexie-react-hooks';
import { addMonths, isAfter } from 'date-fns';

export type RecentViewedLeadData = Pick<
  Lead,
  | 'id'
  | 'identifier'
  | 'gid'
  | 'processedAt'
  | 'accessPermittedAt'
  | 'contacts'
  | 'property'
>;

export interface RecentViewedLead {
  /**
   * The recent viewed leads identifier used as readable ID
   */
  identifier: string | null;
  /**
   * Indicated the last viewed time, used for sorting
   */
  lastViewed: string;
  /**
   * The lead data, needed for display on dashboard.
   */
  lead: RecentViewedLeadData;
}

interface RecentViewedExpiry {
  dbName: string;
  expireDate: string;
}

type RecentViewedLeadTable = {
  leads: EntityTable<RecentViewedLead, 'identifier'>;
};

type RecentViewedExpireTable = {
  expireDbs: EntityTable<RecentViewedExpiry, 'dbName'>;
};

type DexieInstance<TableType> = (Dexie & TableType) | undefined;

interface DexieInstances {
  recentLeadsDb: DexieInstance<RecentViewedLeadTable | undefined>;
  expireDatesDb: DexieInstance<RecentViewedExpireTable | undefined>;
}

export const RecentViewedLeadsContext = createContext<{
  setUserId: Dispatch<SetStateAction<User['id'] | undefined>>;
  setDbInstances: Dispatch<SetStateAction<DexieInstances>>;
  dbInstances: DexieInstances;
  recentViewedLeads: RecentViewedLead[] | [];
}>({
  setUserId: () => {},
  setDbInstances: () => {},
  dbInstances: { recentLeadsDb: undefined, expireDatesDb: undefined },
  recentViewedLeads: [],
});

const MAX_DATABASE_TO_KEEP = 3;
const EXPIRY_TIME_MONTH = 6;

/**
 * Provider for the user dedicated recent leads.
 *
 * Handles the creation, opening of user dependent leads IndexedDb Stores and provides queried recent viewed leads.
 */
const RecentViewedLeadsProvider = ({ children }: { children: ReactNode }) => {
  const [userId, setUserId] = useState<User['id'] | undefined>(undefined);

  const [dbs, setDbs] = useState<DexieInstances>({
    recentLeadsDb: undefined,
    expireDatesDb: undefined,
  });

  useEffect(() => {
    (async () => {
      /**
       * Create new dexie instance namespaced by the userId.
       *
       * When this db already exists, this db will just be instantiated through dexie but not overwritten.
       */
      const newDb = new Dexie(
        `recentViewed-${userId}`
      ) as DexieInstance<RecentViewedLeadTable>;

      /**
       * In case we have no userId (logged out) delete the auto created indexedDB, and unset the db state, we do not need it.
       */
      if (!userId && newDb) {
        await newDb.delete();
      }

      /**
       * Save new db instance in state
       *
       * In case we have a new user logged in, the database instance is switched to the new one and this will be saved in state to be usable through the app.
       */
      if (userId && newDb && newDb.name !== dbs.recentLeadsDb?.name) {
        setDbs({ ...dbs, recentLeadsDb: newDb });
      }

      /**
       * Schema declaration
       *
       * Defines the keys to be indexed. We do not need to index everything,
       * nor we should e.g. do NOT index images, movies or large (huge) strings. You can
       * add them but must not index them.
       */
      const leadsTableSchema = { leads: 'identifier, lastViewed' };

      /**
       * This may throw an error, because the hook is run multiple times and the db may be
       * open. That is why it is wrapped in try/catch.
       * When you change something in the schema, raise the version here. dexie will
       * migrate existing dbs then.
       */
      try {
        if (userId) {
          dbs.recentLeadsDb?.version(1)?.stores(leadsTableSchema);
        }
      } catch {
        /* Empty */
      }
    })();
  }, [dbs, userId]);

  /**
   * Opens or creates a db to track expire dates for recent viewed leads user databases.
   */
  useEffect(() => {
    const expireDatesDb = new Dexie(
      'recentViewed-expireDates'
    ) as DexieInstance<RecentViewedExpireTable>;

    // Define table schemas, first string is always the primary key and will be indexed, following strings are additional keys to index. Only add keys here that need to be indexed.
    expireDatesDb?.version(1).stores({ expireDbs: 'dbName, expireDate' });

    if (userId && !dbs?.expireDatesDb) {
      setDbs({ ...dbs, expireDatesDb });
    }
  }, [dbs, userId]);

  /**
   * Update expire entry for current database,
   * after that run garbageCollection
   */
  useEffect(() => {
    /**
     * Updates the database expires times.
     *
     * When this is called the expiry time of the users recent leads database is set X month in the future from time of login.
     */
    const updateDatabaseExpiryDate = async (
      expireDatesDb: DexieInstance<RecentViewedExpireTable>,
      dbName: string
    ) => {
      const today = new Date();
      const expireDate = addMonths(today, EXPIRY_TIME_MONTH).toISOString();

      return expireDatesDb?.expireDbs?.put({ dbName, expireDate });
    };

    /**
     * Garbage Collection
     *
     * Deletes the database that was updated the longest time ago in case the max
     * limit is reached, also clears all databases that have reached the expiry date.
     */
    const runGarbageCollection = async (
      expireDatesDb: DexieInstance<RecentViewedExpireTable>
    ) => {
      const collection = expireDatesDb?.expireDbs?.orderBy('expireDate');
      const count = (await collection?.count()) || 0;

      /**
       * Delete the users database, that is next to expire in case the max kept limit is reached.
       */
      if (collection && count > MAX_DATABASE_TO_KEEP) {
        const oldestEntry = await collection.first((entry) => entry);

        if (oldestEntry) {
          await Dexie.delete(oldestEntry.dbName);
          await collection.limit(1).delete();
        }
      }

      // Collect all entries of expired databases
      const expiredEntriesCollection = collection?.filter((entry) =>
        isAfter(new Date(), new Date(entry.expireDate))
      );

      if (expiredEntriesCollection) {
        // Use collection of expired entries to delete corresponding databases, after that, clear the expired entries.
        await expiredEntriesCollection.eachPrimaryKey((dbName) =>
          Dexie.delete(dbName)
        );

        await expiredEntriesCollection.delete();
      }
    };

    (async () => {
      if (
        dbs.recentLeadsDb &&
        dbs.recentLeadsDb.name === `recentViewed-${userId}`
      ) {
        await updateDatabaseExpiryDate(
          dbs.expireDatesDb,
          dbs.recentLeadsDb.name
        );
        await runGarbageCollection(dbs.expireDatesDb);
      }
    })();
  }, [dbs, userId]);

  /**
   * Recent viewed leads from IndexedDB
   *
   * The livequery is executed here, to give the app access to its data
   * early. Doing so the data will be loaded before the homepage is even rendered.
   * Preventing potential flickers (e.g. showing no-leads-view text for 1 second). The livequery will automatically prefetch data when the indexed db state changes (e.g. putting recent leads in on detail page).
   */
  const recentViewedLeads = useLiveQuery(
    async () =>
      dbs.recentLeadsDb?.leads
        ?.orderBy('lastViewed')
        ?.reverse()
        ?.limit(10)
        ?.toArray()
        ?.then((leads) => leads),
    [dbs]
  );

  const expireDbEntries = useLiveQuery(
    async () => dbs.expireDatesDb?.expireDbs?.toArray(),
    [dbs]
  );

  /**
   * RecentViewedLeadsContext Provider value
   *
   * dbInstance to update recent leads, recent viewed leads for display on dashboard, setter function to unset userId and db instance in case we are logged out.
   */
  const providerValue = useMemo(
    () => ({
      setUserId,
      setDbInstances: setDbs,
      dbInstances: dbs,
      recentViewedLeads: recentViewedLeads || [],
      expireDbEntries: expireDbEntries || [],
    }),

    [dbs, expireDbEntries, recentViewedLeads]
  );

  return (
    <RecentViewedLeadsContext.Provider value={providerValue}>
      {children}
    </RecentViewedLeadsContext.Provider>
  );
};

export default RecentViewedLeadsProvider;
