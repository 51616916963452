import { PropertyPropertyType } from 'app-graphql';

import {
  RealtyFlatSimple,
  RealtyCommercialSimple,
  RealtyHouseSimple,
  RealtySiteSimple,
  RealtyOtherSimple,
} from '@hausgold/designsystem/icons';

/** Results per page (for paginations, lists) */
export const RESULTS_PER_PAGE = 20;

/**
 * Returns the "main" type as the broker knows them instead of the technical and more detailed type from backend.
 * @param {string | undefined | null} propertyType
 * @returns {'flat' | 'house' | 'site' | 'commercial' | 'other'}
 */
export const PROPERTY_TYPE = (propertyType) => {
  switch (propertyType) {
    case PropertyPropertyType.Room:
    case PropertyPropertyType.Apartment:
      return 'flat';
    case PropertyPropertyType.House:
      return 'house';
    case PropertyPropertyType.Site:
    case PropertyPropertyType.Agriculture:
      return 'site';
    case PropertyPropertyType.Office:
    case PropertyPropertyType.Retail:
    case PropertyPropertyType.HospitalityIndustry:
    case PropertyPropertyType.Warehouse:
    case PropertyPropertyType.Parking:
    case PropertyPropertyType.LeisureCommercial:
    case PropertyPropertyType.InterestHouse:
      return 'commercial';
    default:
      return 'other';
  }
};

/**
 * Returns a property icon (as a react element) for a given property type.
 *
 * @param {string} propertyType
 * @return {React.ReactElement}
 */
export const PROPERTY_TYPE_ICON = (propertyType) => {
  const propertygroup = PROPERTY_TYPE(propertyType);
  switch (propertygroup) {
    case 'flat':
      return RealtyFlatSimple;
    case 'house':
      return RealtyHouseSimple;
    case 'site':
      return RealtySiteSimple;
    case 'commercial':
      return RealtyCommercialSimple;
    default:
      return RealtyOtherSimple;
  }
};

/**
 * Decides when the layout on the lead detail page is switched.
 */
export const BREAKPOINT = 'lg';

/**
 * The Sidebar width
 *
 * This is used for HAUSGOLD sidebar, that is different in size than chakra's responsive values. Closest is `2xs`, which is 256px. We use 17 * 16, which is 272px.
 * Original Maklerportal uses 275px.
 *
 * CAUTION: This constant is used in different places to keep the Sidebar, PageSlide and DrawerContent in sync.
 */

export const SIDEBAR_WIDTH = '272px';

/**
 * The SimpleGrid props used by the LeadList and other grid components to make them flush with each other.
 * @type {{sx: {justifyContent: string}, gap: number, templateColumns: {lg: string, base: string}}}
 */

export const CARD_GRID_PROPS = {
  templateColumns: {
    base: '1fr',
    lg: 'repeat(2, minmax(10px, 500px))',
  },
  sx: {
    justifyContent: 'center',
  },
  gap: 6,
  alignItems: 'stretch',
};

/**
 * PropertyIcon sizes
 */

export const PROPERTY_TYPE_ICON_SIZES = {
  sm: '14px',
  md: '18px',
  lg: '25px',
};

/**
 * This email ending indicates that the provided email is fake and only used as placeholder for backend.
 * No user can be reached with.
 */
export const PLACEHOLDER_MAIL = '@no-email.hausgold.de';

/**
 * Page Content Padding
 */
export const PAGE_CONTENT_PADDING = 10;
export const PAGE_CONTAINER_STYLES = {
  maxW: 940,
  py: PAGE_CONTENT_PADDING,
  px: { base: 4, [BREAKPOINT]: 5 },
};

/**
 * Size of the ContentSwitcher (Tabs Navigation) and it's icons
 */
export const CONTENT_SWITCHER_SIZE = 'md';
export const CONTENT_SWITCHER_ICON_SIZE = '21px';
